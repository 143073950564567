export const CheckmarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="55"
      viewBox="0 0 54 55"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.999756"
        width="53"
        height="53"
        rx="26.5"
        stroke="#036471"
      />
      <path
        d="M22 28.8634L25.7333 32.4998L36 22.4998"
        stroke="#20FFFF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
