export const RightChevronIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
    >
      <path
        d="M10.1387 8.42893C10.1223 8.25438 10.0462 8.08922 9.9212 7.95724L2.73709 0.305681C2.58362 0.131572 2.35983 0.0223707 2.11726 0.00304238C1.87468 -0.016143 1.63397 0.0559828 1.45044 0.203264C1.26675 0.350546 1.15587 0.560144 1.14319 0.784148C1.1305 1.00815 1.21711 1.22729 1.3831 1.39129L8.05526 8.5L1.3831 15.6087C1.21711 15.7727 1.1305 15.9918 1.14319 16.2159C1.15587 16.4399 1.26675 16.6495 1.45044 16.7967C1.63398 16.944 1.87467 17.0161 2.11726 16.997C2.35983 16.9776 2.58362 16.8684 2.73709 16.6943L9.9212 9.04276C10.0809 8.8737 10.1594 8.65213 10.1387 8.42882L10.1387 8.42893Z"
        fill="white"
      />
    </svg>
  )
}
