export interface IRouterOptions {
  isReady: boolean
  page: string | string[] | undefined
  slug: string | Array<string> | undefined
  onPushNextRouter: (
    slug: string,
    path?: string | undefined,
    options?: Record<string, unknown>,
  ) => Promise<boolean>
  onReplaceNextRouter: (
    url: string,
    as?: string | undefined,
    options?: Record<string, unknown>,
  ) => Promise<boolean>
  selectedVideoId?: string | string[] | undefined
}

export interface ISubmitVoteReq {
  name: string
  email: string
  videoId: string
  videoEducationLevel: number
}

export interface IGallery {
  data?: Array<IStudentGalleryVideo | IVimeoGalleryVideo>
  numThumbnailsPerPage: number
  nextJSOptions: IRouterOptions
  tabLabels?: Array<EducationLevelTabs | string>
  resultsHeading: string
  filterHeading?: string
  sortByHeading?: string
  sortByOptions?: string[]
  filterValue?: string
  tabValue?: string
  activeTabIndex?: number
  leaderboard?: JSX.Element
  submitVote?: ({
    name,
    email,
    videoId,
    videoEducationLevel,
  }: ISubmitVoteReq) => Promise<any>
  isLoading: boolean
  displayShareButton?: (
    title: string,
    url?: string,
    type?: 'result-modal' | undefined,
  ) => JSX.Element
  votingModalReminder?: string
  votingModalDisclaimer?: string
  isVotingOpen?: () => boolean
  useUniqueLinksForModals?: boolean
  enableVideoHover?: boolean
  useDetailsSlug?: boolean
  usePagination?: boolean
  showTags?: boolean
}

export interface IVideoDetails {
  tags: Tags[]
  url: string
  loDefVideo?: string
  posterImage: string
  title?: string
  uploadDate?: string
}

export interface Tags {
  category: string
  name: string
}

export interface IGalleryVideo {
  sId: string
  video: IVideoDetails
  thumbnail?: string
}

export interface IStudentGalleryVideo extends IGalleryVideo {
  firstName: string
  lastName: string
  educationLevel: number
  schoolName: string
  nextImage?: React.ReactElement
  artWorkTitle: string
  createdAt: string
}

export interface IVimeoGalleryVideo extends IGalleryVideo {
  videoDescription?: string
  autoplay: boolean
  displayThumbnail: boolean
  externalUrl: string
  nextImage?: React.ReactElement
  enableVideoHover?: boolean
}

export type IVideo = IGalleryVideo | IStudentGalleryVideo | IVimeoGalleryVideo

export enum EducationLevelTabs {
  Elementary = 'Elementary_School',
  Middle = 'Middle_School',
  High = 'High_School',
  College = 'College',
}

export interface IFilterState {
  allFilters: string[] | { categoryName: string; tagNames: string[]; }[];
  selectedFilters: Array<string>
  filteredData: Array<IVimeoGalleryVideo | IGalleryVideo | IStudentGalleryVideo>
}

export interface IPaginationState {
  numPages: number
  currentPage: number
  currentPageData: Array<
    IVimeoGalleryVideo | IGalleryVideo | IStudentGalleryVideo
  >
}

export interface IGalleryState {
  filters: IFilterState
  pagination: IPaginationState
}

export const EducationLevels: string[] = [
  '',
  '',
  '',
  '',
  '4th Grade',
  '5th Grade',
  '6th Grade',
  '7th Grade',
  '8th Grade',
  '9th Grade',
  '10th Grade',
  '11th Grade',
  '12th Grade',
  'Freshman',
  'Sophomore',
  'Junior',
  'Senior',
  'Grad Student',
]
