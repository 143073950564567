import { styled } from 'styled-components'

export const StyledModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); //rgba(var(--black-rgba), 0.8);
  backdrop-filter: blur(8px);
  overflow: auto;

  .modal-dialog {
    width: auto;
    height: 100%;
    display: flex;
    padding: 15px;
  }

  .modal-content {
    position: relative;
    padding: 24px; //var(--spacing-3) var(--spacing-3);
    margin: auto;
    background-color: #1b1b1b; //var(--gray-900);
    border-radius: 8px; //var(--spacing-1);

    &.modal-content--wide {
      //Resizes the Gallery modal contents while maintaining aspect ratio when the viewport gets smaller
      width: 134vh;
      max-width: 1280px;
      padding: 0;
    }

    &.modal-content--extra-wide {
      width: 160vh;
      max-width: 1267px;
      padding: 0;

      @media (max-width: 1127px) {
        width: 100%;
      }
    }

    // Assumes button is at bottom of modal
    button:last-child {
      @media (max-width: 767px) {
        // required to prevent iOS Chrome bottom bar from overlapping
        // do not change unless specifically required
        margin-bottom: 32px; //var(--spacing-4);
      }
    }
  }
`

export const StyledIconCloseContainer = styled.button`
  border: none;
  position: absolute;
  padding: 12px;
  z-index: 10000;
  cursor: pointer;
  border-radius: 50%;
  background: transparent;
  color: #fff; //var(--white);
  top: 6px;
  right: 6px;
  width: 40px;
  height: 40px;
  transition: opacity 0.8s linear;

  &:hover {
    background: linear-gradient(
      250deg,
      #fff 19%,
      #c6c6c6 75%
    ); //linear-gradient(250deg, var(--white) 19%, var(--gray-200) 75%);

    svg path {
      fill: #000;
      opacity: 0.9;
    }
  }

  svg {
    width: 16px;
    height: 16px;

    @media (max-width: 767px) {
      width: 12px;
      height: 12px;
    }
  }

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
    padding: 10px;
  }
`
