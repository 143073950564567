import { StyledNavTab, StyledNavigationTabs } from './NavigationTabs.styled'
import { NavigationTabProps } from '../../interfaces/navigationTab'
import { EducationLevelTabs } from '../../interfaces/gallery'
import { CASING, NAV_TABS_TYPES } from '../../utils'
import { KeyboardEventHandler, useCallback } from 'react'

export const NavigationTabs = ({
  activeIndex,
  setActiveIndex,
  labels,
  casing,
  type = NAV_TABS_TYPES.DEFAULT,
}: NavigationTabProps) => {
  const handleKeyEvent: KeyboardEventHandler = useCallback(
    (event: React.KeyboardEvent<HTMLSpanElement>) => {
      const { key } = event
      if (key === ' ' || key === 'Spacebar') {
        event.preventDefault()
        const tabId = Number(
          (event.target as HTMLSpanElement).getAttribute('id'),
        )
        setActiveIndex(tabId)
      }
    },
    [],
  )

  return (
    <>
      {labels.length && (
        <StyledNavigationTabs $type={type}>
          {labels.map((label: string, index: number) => {
            const isActive = activeIndex === index
            if (label === EducationLevelTabs.Elementary) {
              //Design team would prefer not to display the word 'school' after elementary
              label = 'Elementary'
            }

            return (
              <StyledNavTab
                id={index.toString()}
                key={index}
                $type={type}
                $isActive={isActive}
                onClick={() => {
                  setActiveIndex(index)
                }}
                tabIndex={0}
                onKeyDown={handleKeyEvent}
              >
                {casing && casing === CASING.UPPER
                  ? label.replaceAll('_', ' ').toUpperCase()
                  : label.replaceAll('_', ' ')}
              </StyledNavTab>
            )
          })}
        </StyledNavigationTabs>
      )}
    </>
  )
}
