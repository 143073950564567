import { styled } from 'styled-components'

export const StyledDropdownSelector = styled.div`
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    margin: auto;
    align-items: center;
  }
`

export const StyledDropdownButton = styled.button<{ $isOpen: boolean }>`
  display: flex;
  padding: 8px 16px;
  width: 100%;
  min-width: 200px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: ${props => (props.$isOpen ? '#000' : 'rgba(0, 0, 0, 0.5)')};
  border: ${props => (props.$isOpen ? '2px solid #fff' : '1px solid #757575')};
  color: ${props => (props.$isOpen ? '#fff' : '#ababab')};
  cursor: pointer;
  font-size: 16px;
  font-weight: 350;
  line-height: 150%;
  margin-top: ${props => (props.$isOpen ? '0px' : '1px')};
  margin-bottom: ${props => (props.$isOpen ? '0px' : '1px')};

  @media (max-width: 320px) {
    width: 100%;
  }

  &:hover {
    background-color: #000;
    color: rgba(255, 255, 255, 1);

    svg g path {
      fill: #fff;
    }
  }

  svg g path {
    fill: ${props => (props.$isOpen ? '#fff' : '#ababab')};
  }

  div {
    @media (min-width: 768px) {
      padding-right: 16px;
    }
  }
`

export const StyledDropdownList = styled.ul<{ $isOpen: boolean }>`
  margin: 0px;
  padding: 8px 0px;
  flex-direction: column;
  min-width: 100%;
  align-items: flex-end;
  border-radius: 8px;
  background: #000;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 8999;
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  top: 100%;

  @media (max-width: 767px) {
    width: 100%;
  }
`

export const StyledLi = styled.li<{
  $isSelected?: boolean
  $whiteSpace?: string
}>`
  display: flex;
  padding: 8px 16px;
  padding-right: 42px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;
  color: ${props => (props.$isSelected ? '#757575' : '#fff')};
  list-style: none;
  position: relative;
  font-size: 18px;
  gap: 8px;
  font-weight: 350;
  width: 100%;
  white-space: ${props => (props.$whiteSpace ? props.$whiteSpace : 'normal')};

  &:hover {
    background: #fff;
    color: #000;
  }
`
