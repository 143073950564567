import React, { useState, useRef } from 'react'
import { useOutsideClick } from '@msgtechnology/ui'
import {
  StyledDropdownButton,
  StyledDropdownList,
  StyledDropdownSelector,
  StyledLi,
} from './DropdownSelector.styled'
import { DownCaretIcon } from '../Icons/DownCaret'
import { DropdownSelectorProps } from '../../interfaces/dropdownSelector'
import { EducationLevelTabs } from '../../interfaces/gallery'

export const DropdownSelector = ({
  onChange,
  options,
  current,
  menuHeading,
  showMenuHeadingInSelectedState = false,
}: DropdownSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<number>(current >= 0 ? current : -1)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleItemClick = async (item: number) => {
    setSelected(item)
    setIsOpen(false)
    await onChange(item)
  }

  useOutsideClick(dropdownRef, () => {
    setIsOpen(false)
  })

  const getLabel = (idx: number) => {
    const currOption = options[idx]
    if (currOption === EducationLevelTabs.Elementary) {
      //Design team would prefer not to display the word 'school' after elementary
      return 'Elementary'
    } else {
      return currOption.replace('_', ' ')
    }
  }

  return (
    <StyledDropdownSelector ref={dropdownRef}>
      <StyledDropdownButton
        $isOpen={isOpen}
        onClick={toggleDropdown}
        tabIndex={0}
      >
        <div>
          {selected < 0
            ? menuHeading
            : showMenuHeadingInSelectedState
            ? `${menuHeading}: ${getLabel(selected)}`
            : `${getLabel(selected)}`}
        </div>
        <DownCaretIcon />
      </StyledDropdownButton>
      <StyledDropdownList $isOpen={isOpen} role="menu">
        {options.map((item, index) => {
          if (item === EducationLevelTabs.Elementary) {
            //Design team would prefer not to display the word 'school' after elementary
            item = 'Elementary'
          }
          return (
            <React.Fragment key={item}>
              <StyledLi
                role="menuItem"
                $isSelected={selected === index}
                $whiteSpace={'nowrap'}
                onClick={() => handleItemClick(index)}
                tabIndex={0}
              >
                {item.replace('_', ' ')}
              </StyledLi>
            </React.Fragment>
          )
        })}
      </StyledDropdownList>
    </StyledDropdownSelector>
  )
}
