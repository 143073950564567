import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { disableBodyScroll } from '@msgtechnology/ui'
import { CheckmarkIcon } from '../Icons/Checkmark'
import {
  CTAButton,
  CTAButtonType,
  CTAContainer,
  ChildContainer,
  ModalContentContainer,
  ModalDialog,
  ResultModalContainer,
} from './ResultModal.styled'
import { CloseIcon } from '../Icons/Close'
import { StyledIconCloseContainer } from '../Modal/Modal.styled'
import { AlertIcon } from '../Icons/Alert'

export enum ResultModalType {
  WARNING = 1,
  INFO = 2,
  ERROR = 3,
  SUCCESS = 4,
}

export interface ConfirmationModalProps {
  children: any
  closeOnOutsideClick?: boolean
  handleModalClose: () => void
  isOpen: boolean
  type: ResultModalType | null
  panel?: boolean
  closeBtnText?: string
  actionButtonText?: string
  actionButtonCallback?: Function
  shareButton?: JSX.Element
  modalCloseAction?: () => void
}

export const ResultModal = ({
  isOpen,
  handleModalClose,
  children,
  closeOnOutsideClick = true,
  type = ResultModalType.WARNING,
  closeBtnText = 'Close',
  actionButtonText = 'Action',
  actionButtonCallback = () => null,
  shareButton,
  modalCloseAction,
}: ConfirmationModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    disableBodyScroll(isOpen)
  }, [isOpen])

  const handleKeyEvent = useCallback((event: KeyboardEvent) => {
    const { key } = event
    if (key === 'Escape' && handleModalClose) {
      handleModalClose()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [handleKeyEvent])

  if (!type) {
    return <></>
  }

  const onModalClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && closeOnOutsideClick) {
      handleModalClose()
    }
  }

  const handleCloseButtonClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    handleModalClose()
    if (modalCloseAction) {
      modalCloseAction()
    }
  }

  const handleActionButtonClick = (
    e: React.MouseEvent<Element, MouseEvent>,
  ) => {
    actionButtonCallback()

    setTimeout(() => {
      handleModalClose()
    }, 100)
  }

  const getInfoIcon = () => {
    switch (type) {
      case ResultModalType.WARNING:
        return <AlertIcon />

      // case ResultModalType.INFO:
      //   return <FontAwesomeIcon icon={faCircleInfo} color="#7f56d9" />

      // case ResultModalType.ERROR:
      //   return <FontAwesomeIcon icon={faExclamationTriangle} color="#D92D20" />

      case ResultModalType.SUCCESS:
        return <CheckmarkIcon />

      default:
        return <></>
    }
  }

  const modalContent = isOpen ? (
    <ResultModalContainer>
      <ModalDialog role="document" onClick={onModalClick}>
        <ModalContentContainer $type={type}>
          <StyledIconCloseContainer
            onClick={handleModalClose}
            data-testid="modal-close-icon-btn"
          >
            <CloseIcon />
          </StyledIconCloseContainer>
          {getInfoIcon()}
          <ChildContainer>{children}</ChildContainer>
          <CTAContainer>
            {shareButton}
            <CTAButton
              $buttonType={CTAButtonType.CLOSE}
              onClick={handleCloseButtonClick}
            >
              {closeBtnText}
            </CTAButton>
            {type === ResultModalType.WARNING && (
              <CTAButton
                $buttonType={CTAButtonType.ACTION}
                onClick={handleActionButtonClick}
              >
                {actionButtonText}
              </CTAButton>
            )}
          </CTAContainer>
        </ModalContentContainer>
      </ModalDialog>
    </ResultModalContainer>
  ) : null

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      //@ts-ignore
      document.getElementById('root-modal'),
    )
  } else {
    return null
  }
}

export default ResultModal
