import { styled } from 'styled-components'
import { NAV_TABS_TYPES } from '../../utils'

export const StyledNavigationTabs = styled.div<{
  $type: string
}>`
  width: 100%;
  text-align: center;
  background: transparent;
  justify-content: ${props =>
    props.$type === NAV_TABS_TYPES.DROPDOWN ? `space-between` : `center`};
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  border-bottom: ${props =>
    props.$type === NAV_TABS_TYPES.DROPDOWN
      ? `1px solid rgba(255, 255, 255, 0.4)`
      : `1px solid #474747`};
  line-height: ${props =>
    props.$type === NAV_TABS_TYPES.DROPDOWN ? `12px` : `initial`};

  @media (min-width: 768px) {
    margin-top: ${props =>
      props.$type === NAV_TABS_TYPES.DROPDOWN ? `0px` : `-40px`};
  }
  gap: ${props =>
    props.$type === NAV_TABS_TYPES.DROPDOWN ? `initial` : `48px`};
`

export const StyledNavTab = styled.span<{
  $type: string
  $isActive: boolean
}>`
  padding: 16px 8px;
  gap: ${props =>
    props.$type === NAV_TABS_TYPES.DROPDOWN && !props.$isActive
      ? `initial`
      : `16px`};
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  white-space: nowrap;
  font-size: ${props =>
    props.$type === NAV_TABS_TYPES.DROPDOWN ? `12px` : `22px`};
  font-weight: ${props =>
    props.$type === NAV_TABS_TYPES.DROPDOWN || props.$isActive ? `600` : `350`};
  color: ${props =>
    props.$isActive
      ? `#fff`
      : props.$type === 'dropdown'
      ? `rgba(255, 255, 255, 0.8)`
      : `#c6c6c6`};
  letter-spacing: ${props =>
    props.$type === NAV_TABS_TYPES.DROPDOWN ? `0.2em` : `initial`};
  border-bottom: ${props => (props.$isActive ? `2px solid #06eaff` : `unset`)};

  &:hover {
    border-bottom: ${props =>
      !props.$isActive ? `2px solid #036471` : `2px solid #06eaff`};
  }

  @media (max-width: 768px) {
    padding: 16px 8px;
    font-size: ${props =>
      props.$type === NAV_TABS_TYPES.DROPDOWN ? `12px` : `18px`};
  }
`
