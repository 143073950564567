import { Col, Container, Row } from 'react-grid-system'
import { IGallery, IGalleryState, IVideo } from '../../interfaces/gallery'
import { useWindowSize, useIsIntersecting } from '@msgtechnology/ui'
import { Heading } from '../Heading'
import { Video } from '../Video'
import { useEffect, useState, useRef } from 'react'
import { Pagination } from '../Pagination'
import GalleryNavigation from './GalleryNavigation'
import {
  StyledContainerDiv,
  StyledGHDropdownContainer,
  StyledGalleryHeading,
} from './Gallery.styled'
import { DropdownMultiSelector } from '../DropdownMultiSelector'
import { DropdownSelector } from '../DropdownSelector'
import { IVoteDetails } from '../../interfaces/video'

export const Gallery = ({
  data = [],
  numThumbnailsPerPage,
  nextJSOptions,
  tabLabels,
  resultsHeading,
  filterHeading,
  sortByHeading,
  sortByOptions,
  filterValue,
  activeTabIndex,
  leaderboard,
  submitVote,
  isLoading,
  displayShareButton,
  votingModalReminder,
  votingModalDisclaimer,
  isVotingOpen,
  useUniqueLinksForModals = false,
  enableVideoHover = false,
  useDetailsSlug,
  usePagination = false, // use lazy loading by default
  showTags = false
}: IGallery) => {
  const { onReplaceNextRouter, onPushNextRouter } = nextJSOptions
  const INITIAL_STATE: IGalleryState = {
    filters: {
      allFilters: [],
      selectedFilters: [],
      filteredData: filterValue ? [] : data,
    },
    pagination: {
      numPages: 1,
      currentPage: nextJSOptions.page ? Number(nextJSOptions.page) : 1,
      currentPageData: [],
    },
  }

  const [state, setState] = useState<IGalleryState>(INITIAL_STATE)
  const [visibleData, setVisibleData] = useState<Array<IVideo>>([]);
  const loadMoreRef = useRef(null);
  const intersectionEntry = useIsIntersecting({ ref: loadMoreRef });

  const { isBreakpoint: isMobileBreakpointOrBelow } = useWindowSize('md', '<=')
  const getPathname = () => {
    return typeof window !== 'undefined' ? window.location.pathname : undefined
  }

  useEffect(() => {
    if (!nextJSOptions.isReady || isLoading) {
      return
    }

    const filters = filterValue ? getFilters(data) : []

    setState(prevState => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          allFilters: filters,
          filteredData: data,
        },
      }
    })
  }, [nextJSOptions.isReady, isLoading, data])

  useEffect(() => {
    //No need to run any pagination code if no data is provided
    if (
      isLoading ||
      !state.filters.filteredData.length ||
      !nextJSOptions.isReady
    ) {
      return
    }
    //When filteredData changes, apply or remove pagination
    const { filteredData } = state.filters

    if (usePagination) {
      if (filteredData.length > numThumbnailsPerPage) {
        const newNumPages = Math.ceil(filteredData.length / numThumbnailsPerPage)
        //If page query param is not passed, default to 1
        const currPage = nextJSOptions.page ? Number(nextJSOptions.page) : 1
        //If page query param is passed, but it doesn't exist, default to 1
        const page = 0 < currPage && currPage <= newNumPages ? currPage : 1
        const newPageData = getPageData(page, newNumPages)
        setState(prevState => {
          return {
            ...prevState,
            pagination: {
              numPages: newNumPages,
              currentPageData: newPageData,
              currentPage: page,
            },
          }
        })
        //Replace the page query parameter with the correct one if necessary
        if (Number(nextJSOptions.page) !== page) {
          onReplaceNextRouter(`${getPathname()}/?page=${page}`, undefined, {
            shallow: true,
          })
        }
      } else {
        //If filteredData does not trigger pagination
        setState(prevState => {
          return {
            ...prevState,
            pagination: {
              numPages: 1,
              currentPageData: filteredData,
              currentPage: 1,
            },
          }
        })
        //Remove the page query parameter
        if (nextJSOptions.page) {
          onReplaceNextRouter(`${getPathname()}`, undefined, {
            shallow: true,
          })
        }
      }
    } else { // use LazyLoading
      setVisibleData(filteredData.slice(0, numThumbnailsPerPage))

      if (nextJSOptions.page) {
        onReplaceNextRouter(`${getPathname()}`, undefined, {
          shallow: true,
        })
      }

    }
  }, [state.filters.filteredData, isLoading, nextJSOptions.isReady])

  useEffect(() => {
    if (!usePagination && intersectionEntry?.isIntersecting) {
      loadMoreItems();
    }
  }, [intersectionEntry]);

  const loadMoreItems = () => {
    const nextItems = filteredData.slice(
      visibleData.length,
      visibleData.length + numThumbnailsPerPage
    );
    setVisibleData(prev => [...prev, ...nextItems]);
  };

  useEffect(() => {
    const { page } = nextJSOptions
    if (page) {
      const newPage = Number(page)
      const newPageData = getPageData(newPage)
      setState(prevState => {
        return {
          ...prevState,
          pagination: {
            ...prevState.pagination,
            currentPageData: newPageData,
            currentPage: newPage,
          },
        }
      })
    }
  }, [nextJSOptions.page])

  const scrollToTop = () => {
    const headingTop = document
      .getElementById('heading-container')
      ?.getBoundingClientRect().top
    const bodyTop = document.body.getBoundingClientRect().top
    const headerHeight = document
      .getElementsByTagName('header')[0]
      ?.getBoundingClientRect().height
    const galleryHeadingLocation = headingTop
      ? headingTop - bodyTop - headerHeight
      : 0
    if (window.scrollY > galleryHeadingLocation) {
      window.scrollTo(0, galleryHeadingLocation)
    }
  }

  const handlePageChange = (event: React.MouseEvent) => {
    event.preventDefault()
    const target = event.target as HTMLButtonElement
    const newPage = Number(target.value)
    applyNewPage(newPage)
  }

  const applyNewPage = async (newPage: number) => {
    scrollToTop()
    await onPushNextRouter(`${getPathname()}/?page=${newPage}`, undefined, {
      shallow: true,
    })
  }

  const getPageData = (page: number, newNumPages?: number): Array<IVideo> => {
    const { filteredData } = state.filters
    const numPages = newNumPages ? newNumPages : state.pagination.numPages
    if (numPages <= 1) {
      return filteredData
    }
    const indexOfLastVideo = page * numThumbnailsPerPage
    const indexOfFirstVideo = indexOfLastVideo - numThumbnailsPerPage
    return filteredData.slice(indexOfFirstVideo, indexOfLastVideo)
  }

const getFilters = (videos: IVideo[]) => {
  // For nested structures (category/tag) 
  if (filterValue && filterValue.includes('.')) {
    const categoryToTagsMap: Record<string, Set<string>> = {};

    videos.forEach((video) => {
      const filterValues = getValue(video, filterValue);
      if (Array.isArray(filterValues)) {
        filterValues.forEach((filter) => {
          if (filter.category && filter.name) {
            if (!categoryToTagsMap[filter.category]) {
              categoryToTagsMap[filter.category] = new Set();
            }
            categoryToTagsMap[filter.category].add(filter.name);
          }
        });
      }
    });

    return Object.keys(categoryToTagsMap).map((categoryName) => ({
      categoryName,
      tagNames: Array.from(categoryToTagsMap[categoryName]),
    }));

  } else {
    // For flat structures (xo-student-challenge)
    const flatFilterValues: Set<string> = new Set();

    videos.forEach((video) => {
      const singleFilterValue = video[filterValue as keyof IVideo];
      if (singleFilterValue && typeof singleFilterValue === 'string') {
        flatFilterValues.add(singleFilterValue);
      }
    });

    return Array.from(flatFilterValues).sort();
  }
};



  const handleNext = (event: React.MouseEvent): void => {
    const { currentPage, numPages } = state.pagination
    const newPage = currentPage + 1
    if (newPage <= numPages) {
      applyNewPage(newPage)
    }
  }

  const handlePrevious = (event: React.MouseEvent): void => {
    const newPage = currentPage - 1
    if (newPage >= 1) {
      applyNewPage(newPage)
    }
  }

  const goToTab = async (idx: number) => {
    if (nextJSOptions.slug && tabLabels) {
      const tab = tabLabels[idx].toLowerCase().replace('_', '-')
      await onPushNextRouter(`/${nextJSOptions.slug[0]}/${tab}`)
    }
  }

  const applySelectedFilters = (newFilters: string[]) => {
    let newFilteredData: Array<IVideo>
    if (newFilters.length && filterValue) {
      newFilteredData = data.filter(video => {
        const value = getValue(video, filterValue)
        if (typeof value === 'string') {
          return newFilters.includes(value)
        } else if (Array.isArray(value)) {
          return value.some(val => newFilters.includes(val.name))
        }
      })
    } else {
      newFilteredData = data
    }
    setState(prevState => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          selectedFilters: newFilters,
          filteredData: newFilteredData,
        },
      }
    })
  }

  const getValue = (obj: any, key: string) => {
    return key.split('.').reduce((acc, part) => acc && acc[part], obj)
  }

  const applySort = (idx: number) => {
    if (sortByOptions) {
      const selectedSortByOption = sortByOptions[idx]
      let sortValue: string = selectedSortByOption.split(' ')[0].toLowerCase()
      if (sortValue === 'student') {
        sortValue = 'firstName'
      }
      if (sortValue === 'school') {
        sortValue = 'schoolName'
      }
      if (sortValue === 'video') {
        sortValue = 'video.title'
      }
      const sortUp: boolean =
        selectedSortByOption[selectedSortByOption.length - 2] === 'Z'
      const sortedData: IVideo[] = filteredData.sort((a, b) => {
        let valueA = getValue(a, sortValue)
        let valueB = getValue(b, sortValue)
        if (valueA && valueB) {
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            valueA = valueA.toLowerCase()
            valueB = valueB.toLowerCase()
          }
          if (valueA < valueB) {
            return sortUp ? -1 : 1
          }
          if (valueA > valueB) {
            return sortUp ? 1 : -1
          }
        }
        return 0
      })
      setState(prevState => {
        return {
          ...prevState,
          filters: {
            ...prevState.filters,
            filteredData: [...sortedData],
          },
        }
      })
    }
  }

  const applyActiveVideoModal = (id: string) => {
    onPushNextRouter(
      `${getPathname()}?${nextJSOptions.page ? `page=${nextJSOptions.page}&` : ''
      }id=${id}`,
      undefined,
      { shallow: true },
    )
  }

  const removeActiveVideoModal = () => {
    onPushNextRouter(
      `${getPathname()}${nextJSOptions.page ? `?page=${nextJSOptions.page}` : ''
      }`,
      undefined,
      {
        shallow: true,
      },
    )
  }

  const getVoteDetails = (video: any): IVoteDetails | undefined => {
    if (video.firstName && isVotingOpen) {
      return {
        studentName: `${video.firstName} ${video.lastName}`,
        schoolName: video.schoolName,
        educationLevel: video.educationLevel,
        artWorkTitle: video.artWorkTitle,
        createdAt: video.createdAt,
        submitVote: submitVote ? submitVote : () => Promise.resolve(),
        reminder: votingModalReminder,
        disclaimer: votingModalDisclaimer,
        isVotingOpen: isVotingOpen,
      }
    } else {
      return undefined
    }
  }

  const goToDetailsPage = (id: string) => {
    onPushNextRouter(`${getPathname()}${useDetailsSlug ? '/details': ''}?sId=${id}`)
  }

  const displayGalleryGrid = (videos: Array<any>) => {
    return (
      <Row direction={'row'} gutterWidth={isMobileBreakpointOrBelow ? 8 : 24}>
        {videos?.map((video,index) => {
          const voteDetails = getVoteDetails(video)
          return (
            <Col xs={6} md={6} lg={4} xl={3} key={index}>
              {(video.video.posterImage || video.nextImage) && (
                <div className={'single-thumbnail'}>
                  <Video
                    id={video.sId}
                    video={video.video}
                    nextImage={video.nextImage ? video.nextImage : undefined}
                    thumbnail={video.thumbnail}
                    voteDetails={voteDetails}
                    useDetailsSlug={useDetailsSlug}
                    displayThumbnail={true}
                    autoplay={true}
                    enableVideoHover={enableVideoHover}
                    goToDetailsPage={() => goToDetailsPage(video.sId)}
                    showTags={showTags}
                    //We are passing the url for the artwork details page to the ShareButton component
                    //which is passed to the Gallery component in msg-sphere-next.
                    shareButton={
                      displayShareButton && getPathname() && voteDetails
                        ? displayShareButton(
                          voteDetails?.studentName,
                          `${window.location.origin}/${getPathname()}?sId=${video.sId
                          }`,
                        )
                        : undefined
                    }
                    applyActiveVideoModal={
                      useUniqueLinksForModals
                        ? applyActiveVideoModal
                        : undefined
                    }
                    removeActiveVideoModal={
                      useUniqueLinksForModals
                        ? removeActiveVideoModal
                        : undefined
                    }
                    activeVideoModal={nextJSOptions.selectedVideoId}
                  />
                </div>
              )}
            </Col>
          )
        })}
      </Row>
    )
  }

  const { pagination, filters } = state
  const { numPages, currentPage, currentPageData } = pagination
  const { allFilters, selectedFilters, filteredData } = filters

  return (
    <Container
      style={{
        maxWidth: '1600px',
        minWidth: '280px',
        width: '100%',
        padding: isMobileBreakpointOrBelow ? '0px 16px' : '0px 24px',
      }}
    >
      <StyledContainerDiv>
        {tabLabels?.length && activeTabIndex !== undefined ? (
          <GalleryNavigation
            tabLabels={tabLabels}
            filterByTab={goToTab}
            activeTabIndex={activeTabIndex}
          />
        ) : null}
        {leaderboard ? leaderboard : null}
        <Row id={'heading-container'}>
          <Col>
            <StyledGalleryHeading>
              <Heading
                level={4}
                margin={'0px'}
              >{`${resultsHeading} (${filteredData.length})`}</Heading>
              <StyledGHDropdownContainer>
                {filterHeading ? (
                  <DropdownMultiSelector
                    options={allFilters}
                    current={selectedFilters}
                    onChange={applySelectedFilters}
                    menuHeading={filterHeading}
                  />
                ) : null}
                {sortByHeading && sortByOptions ? (
                  <DropdownSelector
                    options={sortByOptions}
                    current={-1}
                    onChange={applySort}
                    menuHeading={sortByHeading}
                    showMenuHeadingInSelectedState={true}
                  />
                ) : null}
              </StyledGHDropdownContainer>
            </StyledGalleryHeading>
          </Col>
        </Row>
        {usePagination
          ? numPages > 1
            ? displayGalleryGrid(currentPageData)
            : displayGalleryGrid(filteredData)
          : displayGalleryGrid(visibleData) // lazy loading
        }
        {/* lazy loading */}
        {!usePagination && <div ref={loadMoreRef} />}

        {usePagination && numPages > 1 ? (
          <Pagination
            key={numPages}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={numPages}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        ) : null}
      </StyledContainerDiv>
    </Container>
  )
}
