import { styled } from 'styled-components'

export const ModalContainer = styled.div`
  background-color: #1b1b1b; //var(--gray-900);
  border-radius: 8px; //var(--spacing-1);
  padding: 16px; //var(--spacing-2);

  .level-1 {
    font-size: 18px;
    font-weight: 350; //var(--font-weight-light);
    margin-bottom: 16px; //var(--spacing-2);
    padding-right: 40px; //var(--spacing-5);

    @media (max-width: 767px) {
      font-size: 16px; //var(--font-size-h5-mobile);
    }
  }
`
export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  video::-webkit-media-controls-panel {
    display: none;
  }

  &:hover {
    video::-webkit-media-controls-panel {
      display: flex;
    }
  }
`

export const StudentModalContainer = styled.div`
  background-color: #1b1b1b; //var(--gray-900);
  border-radius: 8px; //var(--spacing-1);
  padding: 42px 24px 24px 24px; //var(--spacing-2);
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 767px) {
    padding: 42px 16px;
  }
`
export const StudentVideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  .vimeo-container {
    flex-grow: 2;
    flex-shrink: 0;
    flex-basis: 445px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 767px) {
      width: 100%;
      flex-basis: 0;
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 24px;
      border-bottom: 1px solid #474747;
    }

    @media (min-width: 1129px) {
      flex-grow: 2.5;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const ModalStudentArtDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .level-3 {
    font-size: 32px;
    font-weight: 350;
    line-height: 120%;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 100%;
    }
  }

  div {
    font-size: 18px;
    font-weight: 350;
    line-height: 150%;

    @media (max-width: 767px) {
      font-size: 14px;
      font-weight: 350;
      line-height: 150%;
    }
  }
`
export const VoteConfirmationText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #fff;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`
