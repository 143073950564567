import { styled } from 'styled-components'

export const StyledNavElement = styled.nav`
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 0;
    margin: 0;

    //Styling that applies to the Galaxy Fold only
    @media (max-width: 320px) {
      gap: 10px;
    }
  }

  li {
    display: inline-block;
  }
`
export const StyledPageLink = styled.button<{ $isSelected?: boolean }>`
  cursor: pointer;
  border-radius: 50%;
  border-color: ${props => (props.$isSelected ? '#fff' : '#ababab')};
  border-style: solid;
  border-width: 2px;
  width: 48px; // var(--spacing-6);
  height: 48px; //var(--spacing-6);
  padding: 5px;
  background-color: ${props => (props.$isSelected ? '#fff' : 'transparent')};
  color: ${props => (props.$isSelected ? '#000' : '#fff')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 2.2px;

  //Styling that applies to the Galaxy Fold only
  @media (max-width: 320px) {
    width: 40px; //var(--spacing-5);
    height: 40px; //var(--spacing-5);
  }

  &:hover {
    background-color: #fff; // var(--gray-300);
    color: #000; //var(--black);
    border-color: #fff; //var(--gray-300);

    svg path {
      fill: #000;
    }
  }
`
