import { useState, useRef, useEffect, useCallback } from 'react';
import { GalleryThumbnailProps } from '../../interfaces/galleryThumbnail';
import { Heading } from '../Heading';
import {
  DetailsContainer,
  GalleryThumbnailContainer,
  ThumbnailContainer,
} from './GalleryThumbnail.styled';
import { StudentArtDetails } from './StudentArtDetails';
import { DisplayTags } from '../DisplayTags'

export const GalleryThumbnail = ({
  posterImage,
  videoTitle,
  useDetailsSlug,
  onClick,
  openModal,
  nextImage,
  thumbnail,
  enableVideoHover,
  loDefVideo,
  voteDetails,
  tags,
  showTags
}: GalleryThumbnailProps) => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleMouseEnter = () => {
    setIsVideoVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVideoVisible(false);
    setIsVideoReady(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video to the beginning
    }
  };

  // cleanup when component unmounts
  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, []);

  const VideoHover = useCallback(() => (
    <video
      ref={videoRef}
      src={loDefVideo}
      style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
      loop
      muted
      playsInline
      // Once the video can play, mark it as ready and start playback
      onCanPlay={() => {
        setIsVideoReady(true);
        videoRef.current?.play();
      }}
      onError={(e) => {
        console.log('Video failed to load: ', e)
        setIsVideoVisible(false)
      }}
    />
  )
    , [isVideoVisible, loDefVideo]);

  const Thumbnail = useCallback(() => {
    if (nextImage) {
      return nextImage;
    } else if (thumbnail || posterImage) {
      return (
        <img
          src={thumbnail ? thumbnail : posterImage}
          alt="Video thumbnail"
          style={{ width: '100%', height: 'auto' }}
        />
      );
    }
    return null;
  }, [nextImage, thumbnail, posterImage]);

  return (
    <>
      {(posterImage || thumbnail || nextImage) && (
        <GalleryThumbnailContainer
          className={'thumbnail-container image-container'}
          onClick={voteDetails || useDetailsSlug ? onClick : openModal}
          $isStudent={!!voteDetails}
          onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
        >
          <ThumbnailContainer>
            {(!isVideoVisible || !isVideoReady) && <Thumbnail />}
            {enableVideoHover && loDefVideo && isVideoVisible && <VideoHover />}
          </ThumbnailContainer>
          {(videoTitle || voteDetails) && (
            <DetailsContainer $isStudent={!!voteDetails}>
              <Heading level={3} levelDisplay={3} margin="0px 0px 8px">
                {voteDetails?.studentName || videoTitle}
              </Heading>
              {voteDetails && (
                <StudentArtDetails
                  openModal={openModal}
                  title={voteDetails.artWorkTitle}
                  educationLevel={voteDetails.educationLevel}
                  schoolName={voteDetails.schoolName}
                  isVotingOpen={voteDetails.isVotingOpen}
                />
              )}
              {showTags ? <DisplayTags tags={tags} /> : null} 
            </DetailsContainer>
          )}
        </GalleryThumbnailContainer>
      )}
    </ >
  );
};
