import React, { useState, useRef } from 'react'
import { useOutsideClick } from '@msgtechnology/ui'
import {
  StyledApplyButton,
  StyledButtonContainer,
  StyledMultiButton,
  StyledMultiDropdownList,
  StyledMultiDropdownSelector,
  StyledMultiMenu,
  StyledCategoryName
} from './DropdownMultiSelector.styled'
import { DownCaretIcon } from '../Icons/DownCaret'
import { DropdownMultiSelectorProps } from '../../interfaces/dropdownSelector'
import { StyledDropdownButton } from '../DropdownSelector/DropdownSelector.styled'
import { SingleSelectorComponent } from './SingleSelectorComponent'

export const DropdownMultiSelector = ({
  onChange,
  options,
  menuHeading,
  current = [],
}: DropdownMultiSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<string[]>(current)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const toggleDropdown = () => setIsOpen(!isOpen)

  const updateSelectedOptions = (item: string) => {
    let updatedSelectedOptions
    if (selectedOptions.includes(item)) {
      updatedSelectedOptions = selectedOptions.filter(value => value !== item)
    } else {
      updatedSelectedOptions = [...selectedOptions, item]
    }
    setSelectedOptions(updatedSelectedOptions)
  }

  useOutsideClick(dropdownRef, () => {
    setSelectedOptions(current)
    setIsOpen(false)
  })

  const getMenuHeading = () => {
    return selectedOptions.length
      ? `${menuHeading} (${selectedOptions.length})`
      : `${menuHeading}`
  }

  const clearFilters = () => {
    setSelectedOptions([])
  }

  const handleApply = () => {
    setIsOpen(false)
    onChange(selectedOptions)
  }

  return (
    <StyledMultiDropdownSelector ref={dropdownRef}>
      <StyledDropdownButton
        $isOpen={isOpen}
        onClick={toggleDropdown}
        tabIndex={0}
      >
        <div>{getMenuHeading()}</div>
        <DownCaretIcon />
      </StyledDropdownButton>
      <StyledMultiMenu $isOpen={isOpen}>
        <StyledMultiDropdownList role="menu">
          {options.map((item) => {
            if (typeof item === 'string') {
              return (
                <SingleSelectorComponent
                  item={item}
                  isChecked={selectedOptions.includes(item)}
                  updateFilterList={updateSelectedOptions}
                  key={item}
                />
              );
            } else {
              return (
                <React.Fragment key={item.categoryName || 'default_key'}>
                  {item.tagNames ? (
                    <>
                      <StyledCategoryName>{item.categoryName}</StyledCategoryName>
                      {item.tagNames.map((tag) => (
                        <SingleSelectorComponent
                          item={tag}
                          isChecked={selectedOptions.includes(tag)}
                          updateFilterList={updateSelectedOptions}
                          key={tag}
                        />
                      ))}
                    </>
                  ) : null}
                </React.Fragment>
              );
            }
          })}
        </StyledMultiDropdownList>
        <StyledButtonContainer
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          <StyledMultiButton $disabled={selectedOptions.length === 0}>
            <button
              disabled={selectedOptions.length === 0}
              onClick={clearFilters}
            >
              Clear all
            </button>
          </StyledMultiButton>
          <StyledApplyButton>
            <button onClick={handleApply}>Apply</button>
          </StyledApplyButton>
        </StyledButtonContainer>
      </StyledMultiMenu>
    </StyledMultiDropdownSelector>
  )
}
