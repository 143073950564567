import React, { useState, useCallback, useEffect, useRef } from 'react'
import { JsonLD, useIsIntersecting, useWindowSize } from '@msgtechnology/ui'
import { GalleryThumbnail } from '../GalleryThumbnail'
import { Modal } from '../Modal'
import { Heading } from '../Heading'
import {
  ModalContainer,
  ModalStudentArtDetails,
  VideoContainer,
} from './Video.styled'
import { IVideoProps } from '../../interfaces/video'
import { EducationLevels } from '../../interfaces/gallery'
import { StudentArtModal } from '../StudentArtModal'

export const Video = ({
  id,
  video,
  thumbnail,
  autoplay = false,
  displayThumbnail = false,
  nextImage,
  videoDescription,
  voteDetails,
  useDetailsSlug,
  goToDetailsPage,
  shareButton,
  applyActiveVideoModal,
  removeActiveVideoModal,
  activeVideoModal,
  enableVideoHover,
  showTags
}: IVideoProps) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const intersectEntry = useIsIntersecting({ ref: containerRef })
  const { isBreakpoint: isBelowMobileBreakpoint } = useWindowSize('md', '<')

  const handlePauseVideo = () => {
    videoRef.current?.pause()
  }

  useEffect(() => {
    if (video.url && intersectEntry) {
      const { isIntersecting } = intersectEntry
      const isLoaded = videoRef.current?.readyState === 4

      if (isIntersecting && !isLoaded) {
        videoRef.current?.load()
      }
    }
  }, [intersectEntry?.isIntersecting, video.url])

  useEffect(() => {
    //If the current video leaves the viewport while it is playing, it will pause
    //It will pause if it is scrolled out of view and/or if another video is selected
    if (intersectEntry) {
      const { isIntersecting } = intersectEntry
      const isPaused = videoRef.current?.paused
      if (!isIntersecting && !isPaused) {
        handlePauseVideo()
      }
    }
  }, [intersectEntry?.isIntersecting])

  useEffect(() => {
    if (activeVideoModal === id) {
      setModalIsOpen(prevState => {
        return !prevState ? true : prevState
      })
    } else {
      setModalIsOpen(prevState => {
        return prevState ? false : prevState
      })
    }
  }, [activeVideoModal])

  const handleModalClose = () => {
    setModalIsOpen(false)
    if (removeActiveVideoModal) {
      removeActiveVideoModal()
    }
  }

  const handleModalOpen: React.MouseEventHandler<
    HTMLDivElement | HTMLButtonElement
  > = e => {
    e.stopPropagation()
    if (voteDetails && !voteDetails.isVotingOpen()) {
      return
    } else {
      setModalIsOpen(true)
      if (applyActiveVideoModal) {
        applyActiveVideoModal(id)
      }
    }
  }
  const [videoHeight, setVideoHeight] = useState<number>()
  const ratio = 0.5625
  const modalPaddingOffset = 60

  // Dynamically determine video height. Without this modal will not close on overlay click.
  const handleChangeVideoWidth = useCallback(() => {
    const height = (window.innerWidth - modalPaddingOffset) * ratio
    return setVideoHeight(height)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleChangeVideoWidth)
    const height = (window.innerWidth - modalPaddingOffset) * ratio
    setVideoHeight(height)

    return () => {
      window.removeEventListener('resize', handleChangeVideoWidth)
    }
  }, [videoHeight, handleChangeVideoWidth])

  const videoElement = (url: string, autoPlay: boolean) => {
    return (
      <>
        <div className={'vimeo-container'} ref={containerRef}>
          <VideoContainer className={'video-container'}>
            <video
              id={id}
              autoPlay={autoPlay}
              loop={false}
              muted
              playsInline={true}
              ref={videoRef}
              poster={video.posterImage}
              width={'100%'}
              height={videoHeight}
              controls
              preload={'none'}
            >
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </VideoContainer>
          {voteDetails ? (
            <ModalStudentArtDetails>
              <Heading level={isBelowMobileBreakpoint ? 3 : 2} levelDisplay={3}>
                {voteDetails.studentName}
              </Heading>
              <div>
                {EducationLevels[voteDetails.educationLevel]},{' '}
                {voteDetails.schoolName}
                <br />
                <span style={{ fontStyle: 'italic' }}>
                  {voteDetails.artWorkTitle}
                </span>
              </div>
            </ModalStudentArtDetails>
          ) : null}
        </div>
      </>
    )
  }

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name: voteDetails ? voteDetails.artWorkTitle : video.title,
    description: voteDetails
      ? voteDetails.artWorkTitle
      : videoDescription
      ? videoDescription
      : video.title,
    thumbnailUrl: thumbnail,
    uploadDate: video.uploadDate ? video.uploadDate : voteDetails?.createdAt,
    contentUrl: video.url,
  }

  return (
    <>
      <JsonLD data={structuredData} />
      {displayThumbnail && video.posterImage ? (
        <>
          <GalleryThumbnail
            videoTitle={video.title}
            posterImage={video.posterImage}
            useDetailsSlug={useDetailsSlug}
            onClick={goToDetailsPage}
            openModal={handleModalOpen}
            nextImage={nextImage ? nextImage : undefined}
            thumbnail={thumbnail}
            enableVideoHover={enableVideoHover}
            loDefVideo={video.loDefVideo}
            voteDetails={voteDetails}
            tags={video.tags}
            showTags={showTags}
          />
          {voteDetails ? (
            <StudentArtModal
              handleModalClose={handleModalClose}
              modalIsOpen={modalIsOpen}
              submitVote={voteDetails.submitVote}
              videoVoteInfo={{
                id: id,
                educationLevel: voteDetails.educationLevel,
              }}
              shareButton={shareButton}
              reminder={voteDetails.reminder}
              disclaimer={voteDetails.disclaimer}
              isVotingOpen={voteDetails.isVotingOpen}
            >
              {videoElement(video.url, autoplay)}
            </StudentArtModal>
          ) : video.url ? (
            <Modal
              handleModalClose={handleModalClose}
              isOpen={modalIsOpen || activeVideoModal === id}
              contentWidth={'extra-wide'}
            >
              <ModalContainer className={'modal-container'}>
                <Heading level={3} levelDisplay={1}>
                  {video.title}
                </Heading>
                {videoElement(video.url, autoplay)}
              </ModalContainer>
            </Modal>
          ) : null}
        </>
      ) : (
        video.url && videoElement(video.url, autoplay)
      )}
    </>
  )
}
