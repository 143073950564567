export const LeftChevronIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
    >
      <path
        d="M0.861285 8.42893C0.877729 8.25438 0.953837 8.08922 1.0788 7.95724L8.26291 0.305681C8.41638 0.131572 8.64017 0.0223707 8.88274 0.00304238C9.12532 -0.016143 9.36603 0.0559828 9.54956 0.203264C9.73325 0.350546 9.84413 0.560144 9.85681 0.784148C9.8695 1.00815 9.78289 1.22729 9.6169 1.39129L2.94474 8.5L9.6169 15.6087C9.78289 15.7727 9.8695 15.9918 9.85681 16.2159C9.84413 16.4399 9.73325 16.6495 9.54956 16.7967C9.36602 16.944 9.12533 17.0161 8.88274 16.997C8.64017 16.9776 8.41638 16.8684 8.26291 16.6943L1.0788 9.04276C0.919071 8.8737 0.840615 8.65213 0.861285 8.42882L0.861285 8.42893Z"
        fill="white"
      />
    </svg>
  )
}
