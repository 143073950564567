import { styled } from 'styled-components'

export const ResultModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); //rgba(var(--black-rgba), 0.8);
  backdrop-filter: blur(8px);
`

export const ModalDialog = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`

export const ModalContentContainer = styled.div<{ $type: number }>`
  position: relative;
  padding: 62px 16px 24px;
  width: 404px;
  background-color: #1b1b1b;
  backdrop-filter: blur(4px);
  border-radius: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`

export const ChildContainer = styled.div`
  margin: auto;
  @media (min-width: 768px) {
    width: 278px;
  }
  @media (max-width: 767px) {
    width: 256px;
  }
`

export const CTAContainer = styled.div`
  display: flex;
  margin: auto;
  gap: 8px;
  flex-direction: row;
  align-items: top;
`

export enum CTAButtonType {
  CLOSE = 1,
  ACTION = 2,
}

export const CTAButton = styled.button<{ $buttonType: CTAButtonType }>`
  border-radius: 40px;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.7px;
  color: ${props =>
    props.$buttonType === CTAButtonType.CLOSE ? `#e2e2e2` : `#000`};
  background-color: ${props =>
    props.$buttonType === CTAButtonType.CLOSE
      ? `#1b1b1b`
      : `linear-gradient(250deg, #c6c6c6 19%, #fff 75%)`};
  border: 1px solid #e2e2e2;
  transition: all 0.4s, border 0.2s;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 134px;
  margin-bottom: 8px;

  &:hover {
    color: #000;
    border: 1px solid transparent;
    background: ${props =>
      props.$buttonType === CTAButtonType.CLOSE
        ? `linear-gradient(250deg, #fff 19%, #c6c6c6 75%)`
        : `linear-gradient(250deg, #fff 19%, #c6c6c6 75%)`};
    cursor: pointer;
  }

  @media (max-width: 767px) {
    width: 124px;
    font-size: 12px;
  }
`
