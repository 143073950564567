import { styled } from 'styled-components'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  flex-basis: 205px;
  flex-shrink: 2;
  flex-grow: 1;
  font-size: 20px;
  line-height: 120%;
  font-weight: 600;

  div {
    text-align: left;
    width: 100%;

    @media (min-width: 768px) {
      max-width: 332px;
    }

    @media (min-width: 321px) and (max-width: 767px) {
      text-align: center;
    }
  }

  p {
    font-size: 14px;
    font-weight: 350;

    @media (max-width: 767px) {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 100%;
  }
`

export const SVotingForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 332px;
  }

  input {
    width: 100%;
  }

  button {
    width: 100%;
  }
`

export const SInputContainer = styled.div<{ $hasError: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  input {
    color: ${props => (props.$hasError ? '#f52500' : '#fff')};
    border-radius: 8px;
    outline: none;
    border: ${props =>
      props.$hasError ? '1px solid #f52500' : '1px solid #757575'};
    font-size: 14px;
    background-color: #1b1b1b;
    padding: 10px;
    position: relative;
    display: flex;
    font-size: 18px;
    cursor: auto;

    &::placeholder {
      color: ${props => (props.$hasError ? '#f52500' : '#ababab')};
    }

    &:focus {
      border-color: ${props => (props.$hasError ? '#f52500' : '#fff')};

      &::placeholder {
        color: transparent;
      }
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      left: 14px;
      top: 0;
      font-size: 12px;
      font-weight: 300;
      background-color: #1b1b1b;
      padding-left: 4px;
      padding-right: 4px;
      color: ${props => (props.$hasError ? '#f52500' : '#fff')};
      display: flex;
    }

    + label {
      position: absolute;
      transform: translateY(-50%);
      left: 14px;
      top: 25%;
      transition: all 0.25s ease-out;
      pointer-events: none;
      color: transparent;
    }
  }

  &:nth-child(2) {
    padding-bottom: 8px;

    @media (max-width: 767px) {
      padding-bottom: 0;
    }
  }
`
export const SubmitButton = styled.button`
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 48px;
  border: none;
  color: #000;
  background: linear-gradient(251deg, #20ffff 19.17%, #05c3dd 75.2%);
  letter-spacing: 0.9px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    background: linear-gradient(248deg, #05c3dd 27.85%, #20ffff 81.23%);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  }

  &:disabled {
    background: #5e5e5e;
    color: #ababab;
  }
`

export const CancelButton = styled.button`
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 48px;
  border: 1px solid #e2e2e2;
  background: transparent;
  color: #fff;
  letter-spacing: 0.9px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    border: 1px solid linear-gradient(248deg, #fff 17.66%, #c6c6c6 71.37%);
    background: linear-gradient(248deg, #fff 17.66%, #c6c6c6 71.37%);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    color: #000;
  }

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`

export const WarningContainer = styled.div`
  color: #f52500;
  font-size: 14px;
  font-weight: 350;
  line-height: 150%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;

  span {
    text-align: left;
  }
`
