import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ModalProps } from '../../interfaces/modal'
import { disableBodyScroll } from '@msgtechnology/ui'
import { StyledIconCloseContainer, StyledModal } from './Modal.styled'
import { CloseIcon } from '../Icons/Close'

export const Modal = ({
  isOpen,
  handleModalClose,
  children,
  closeOnOutsideClick = true,
  contentWidth,
}: ModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    disableBodyScroll(isOpen)
  }, [isOpen])

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event
      if (key === 'Escape' && isOpen && handleModalClose) {
        handleModalClose()
      }
    },
    [isOpen],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [handleKeyEvent])

  const onModalClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && closeOnOutsideClick) {
      handleModalClose()
    }
  }

  const modalContent = isOpen ? (
    <StyledModal>
      <div className={'modal-dialog'} role="document" onClick={onModalClick}>
        <div className={`modal-content modal-content--${contentWidth}`}>
          <StyledIconCloseContainer onClick={handleModalClose}>
            <CloseIcon />
          </StyledIconCloseContainer>
          {children}
        </div>
      </div>
    </StyledModal>
  ) : null

  if (isBrowser) {
    const root = document.getElementById('root-modal')
    if (root) {
      return ReactDOM.createPortal(modalContent, root)
    } else {
      return null
    }
  } else {
    return null
  }
}
