import { SyntheticEvent, useState } from 'react'
import {
  CancelButton,
  FormContainer,
  SInputContainer,
  SVotingForm,
  SubmitButton,
  WarningContainer,
} from './VotingForm.styled'
import { WarningIcon } from '../Icons/Warning'
import {
  IInvalidFields,
  IVotingFormProps,
  IVoteSubmission,
} from '../../interfaces/voting'

export const VotingForm = ({
  closeModal,
  openConfirmationModal,
  submitVote,
  videoVoteInfo,
  reminder,
  disclaimer,
  isVotingOpen,
}: IVotingFormProps) => {
  const [voterName, setVoterName] = useState<string>('')
  const [voterEmail, setVoterEmail] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [invalidFields, setInvalidFields] = useState<IInvalidFields>({})

  const emailRegex =
    '^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$'
  const nameRegex = '[a-zA-Z]'
  const requiredMessage = 'This field is required!'

  const handleInputValidation = (e: SyntheticEvent<IVoteSubmission>) => {
    const invalidFields: IInvalidFields = {}
    const nameRegexTester = new RegExp(nameRegex)
    const emailRegexTester = new RegExp(emailRegex)

    if (e.currentTarget.elements.name.value === '') {
      invalidFields.name = requiredMessage
    } else if (!nameRegexTester.test(e.currentTarget.elements.name.value)) {
      invalidFields.name = 'This name is invalid!'
    }

    if (e.currentTarget.elements.email.value === '') {
      invalidFields.email = requiredMessage
    } else if (!emailRegexTester.test(e.currentTarget.elements.email.value)) {
      invalidFields.email = 'This email is invalid!'
    }
    return invalidFields
  }

  const handleFailedSubmission = (error: any) => {
    const invalidFields: IInvalidFields = {}
    if (error.message === 'This email has already voted for this cohort!') {
      invalidFields.email = error.message
    } else {
      invalidFields.name = error.message
      invalidFields.email = error.message
    }

    setInvalidFields(invalidFields)
  }

  const onSubmit = async (e: React.SyntheticEvent<IVoteSubmission>) => {
    e.preventDefault()
    setIsDisabled(true)
    const invalidFields = handleInputValidation(
      e as React.SyntheticEvent<IVoteSubmission>,
    )

    if (!invalidFields || Object.keys(invalidFields).length === 0) {
      try {
        const req = {
          name: voterName,
          email: voterEmail,
          videoId: videoVoteInfo.id,
          videoEducationLevel: videoVoteInfo.educationLevel,
        }
        await submitVote(req)
        setIsDisabled(false)
        closeModal(e as unknown as React.MouseEvent<HTMLButtonElement>)
        openConfirmationModal()
      } catch (err) {
        console.error(err)
        setIsDisabled(false)
        handleFailedSubmission(err)
      }
    } else {
      setInvalidFields(invalidFields)
      setIsDisabled(false)
    }
  }

  return (
    <FormContainer>
      <div>
        Cast your vote for this design!
        {reminder ? <p>{reminder}</p> : null}
      </div>

      <SVotingForm noValidate onSubmit={onSubmit}>
        <SInputContainer $hasError={!!invalidFields.name}>
          <input
            type="text"
            placeholder={'Your Name *'}
            id={'name'}
            value={voterName}
            onChange={e => setVoterName(e.target.value)}
            tabIndex={0}
          />
          <label htmlFor={'name'}>Your Name *</label>
          {invalidFields && invalidFields.name ? (
            <WarningContainer>
              <WarningIcon />
              <span>{invalidFields.name}</span>
            </WarningContainer>
          ) : null}
        </SInputContainer>
        <SInputContainer $hasError={!!invalidFields.email}>
          <input
            type="email"
            placeholder={'Your Email *'}
            id={'email'}
            value={voterEmail}
            onChange={e => setVoterEmail(e.target.value)}
            tabIndex={0}
          />
          <label htmlFor={'email'}>Your Email *</label>
          {invalidFields && invalidFields.email ? (
            <WarningContainer>
              <WarningIcon />
              <span>{invalidFields.email}</span>
            </WarningContainer>
          ) : null}
        </SInputContainer>
        <SubmitButton type="submit" disabled={isDisabled} tabIndex={0}>
          Submit
        </SubmitButton>
        <CancelButton onClick={closeModal} tabIndex={0}>
          Cancel
        </CancelButton>
        {disclaimer ? <p>{disclaimer}</p> : null}
      </SVotingForm>
    </FormContainer>
  )
}
