import { useState } from 'react'
import { StyledLi } from '../DropdownSelector/DropdownSelector.styled'
import { StyledCheckbox, StyledLabel } from './DropdownMultiSelector.styled'

interface ISingleSelector {
  item: string
  isChecked: boolean
  updateFilterList: Function
}

export const SingleSelectorComponent = ({
  item,
  isChecked,
  updateFilterList,
}: ISingleSelector) => {
  const [checked, setChecked] = useState<boolean>(isChecked)

  const toggleCheck: React.ChangeEventHandler<HTMLInputElement> = e => {
    setChecked(!checked)
    updateFilterList(item)
  }

  return (
    <StyledLi key={`${item}`}>
      <StyledCheckbox
        type="checkbox"
        id={`${item}`}
        name="filter"
        onChange={toggleCheck}
        checked={isChecked}
        value={item}
        autoComplete={'off'}
      />
      <StyledLabel role="menuItem" htmlFor={`${item}`} tabIndex={0}>
        {item}
      </StyledLabel>
    </StyledLi>
  )
}
