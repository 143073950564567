import { styled } from 'styled-components'

export const StyledContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 768px) {
    gap: 24px;
  }

  @media (min-width: 1440px) {
    gap: 40px;
  }
`

export const StyledGalleryHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`
export const StyledGHDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`
