import { useEffect, useState } from 'react'
import { PaginationProps } from '../../interfaces/pagination'
import { StyledNavElement, StyledPageLink } from './Pagination.styled'
import { RightChevronIcon } from '../Icons/RightChevron'
import { LeftChevronIcon } from '../Icons/LeftChevron'

export const Pagination = ({
  currentPage,
  onPageChange,
  totalPages,
  onNext,
  onPrevious,
}: PaginationProps) => {
  const [visiblePageNums, setVisiblePageNums] = useState<number[]>([])

  const pageNums = Array.from({ length: totalPages }, (_, i) => i + 1)

  useEffect(() => {
    //If there are more than 3 pages, we limit the number of page #s that are shown
    if (totalPages > 3) {
      let visiblePageSlice: number[] = []
      if (currentPage === 1) {
        visiblePageSlice = pageNums.slice(0, 4)
      } else {
        if (currentPage > 1 && currentPage <= 3) {
          //Prevent the change of visible page #s if the newly selected page is currently visible
          if (
            visiblePageNums.length === 3 &&
            visiblePageNums.includes(currentPage)
          ) {
            return
          }
          visiblePageSlice = pageNums.slice(0, 3)
        }
        if (currentPage > 3) {
          if (currentPage === pageNums[pageNums.length - 1]) {
            visiblePageSlice = pageNums.slice(currentPage - 4, currentPage)
          } else {
            //Prevent the change of visible page #s if the newly selected page is currently visible
            if (
              visiblePageNums.length === 3 &&
              visiblePageNums.includes(currentPage)
            ) {
              return
            }
            visiblePageSlice = pageNums.slice(currentPage - 3, currentPage)
          }
        }
      }
      setVisiblePageNums(visiblePageSlice)
    } else {
      //If there are 3 pages of results or less, display all page #s
      setVisiblePageNums(pageNums)
    }
  }, [currentPage])

  const PageLink = (pageNum: number) => {
    const isSelected = pageNum === currentPage
    return (
      <li key={pageNum}>
        <StyledPageLink
          $isSelected={isSelected}
          onClick={onPageChange}
          value={pageNum}
        >
          {pageNum}
        </StyledPageLink>
      </li>
    )
  }

  return (
    <StyledNavElement role="navigation">
      <ul>
        {currentPage > 1 ? (
          <li>
            <StyledPageLink onClick={onPrevious}>
              <LeftChevronIcon />
            </StyledPageLink>
          </li>
        ) : (
          ''
        )}
        {visiblePageNums.map(num => PageLink(num))}
        {currentPage < pageNums[pageNums.length - 1] ? (
          <li>
            <StyledPageLink onClick={onNext}>
              <RightChevronIcon />
            </StyledPageLink>
          </li>
        ) : (
          ''
        )}
      </ul>
    </StyledNavElement>
  )
}
