import { useWindowSize } from '@msgtechnology/ui'
import { DropdownSelector } from '../DropdownSelector'
import { NavigationTabs } from '../NavigationTabs'

interface GalleryNavigationProps {
  filterByTab: Function
  tabLabels: Array<string>
  activeTabIndex: number
}

const GalleryNavigation = ({
  filterByTab,
  tabLabels,
  activeTabIndex,
}: GalleryNavigationProps) => {
  const { isBreakpoint: isBelowMobileBreakpoint } = useWindowSize('md', '<')

  if (isBelowMobileBreakpoint) {
    return (
      <DropdownSelector
        key={activeTabIndex + tabLabels[activeTabIndex]}
        onChange={filterByTab}
        options={tabLabels?.length ? tabLabels : []}
        current={activeTabIndex}
        menuHeading={'Select student group'}
      />
    )
  } else {
    return (
      <NavigationTabs
        labels={tabLabels?.length ? tabLabels : []}
        activeIndex={activeTabIndex}
        setActiveIndex={filterByTab}
      />
    )
  }
}

export default GalleryNavigation
