export const DownCaretIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Foundations/Iconography">
        <path
          id="Vector"
          d="M1.94338 4.09888L14.0566 4.09888C14.8954 4.09888 15.3155 5.11299 14.7224 5.70608L8.66573 11.7627C8.29808 12.1304 7.70194 12.1304 7.33429 11.7627L1.27766 5.70608C0.684523 5.11299 1.10461 4.09888 1.94338 4.09888Z"
          fill="#ABABAB"
        />
      </g>
    </svg>
  )
}
