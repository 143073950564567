import { useState } from 'react'
import ResultModal, { ResultModalType } from '../ResultModal'
import {
  StudentModalContainer,
  StudentVideoContainer,
  VoteConfirmationText,
} from '../Video/Video.styled'
import { Modal } from '../Modal'
import { VotingForm } from '../VotingForm/VotingForm'
import { IVideoVoteInfo } from '../../interfaces/voting'
import { ISubmitVoteReq } from '../../interfaces/gallery'

export interface IStudentArtModal {
  videoVoteInfo: IVideoVoteInfo
  children: any
  handleModalClose: () => void
  modalIsOpen: boolean
  submitVote: ({
    name,
    email,
    videoId,
    videoEducationLevel,
  }: ISubmitVoteReq) => Promise<any>
  shareButton?: JSX.Element
  reminder?: string
  disclaimer?: string
  confirmationModalCloseAction?: () => void
  isVotingOpen: () => boolean
}

export const StudentArtModal = ({
  videoVoteInfo,
  children,
  handleModalClose,
  modalIsOpen,
  submitVote,
  shareButton,
  reminder,
  disclaimer,
  confirmationModalCloseAction,
  isVotingOpen,
}: IStudentArtModal) => {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
    useState<boolean>(false)
  return (
    <>
      <Modal
        handleModalClose={handleModalClose}
        isOpen={modalIsOpen}
        contentWidth={'extra-wide'}
      >
        <StudentModalContainer>
          <StudentVideoContainer>
            {children}
            <VotingForm
              videoVoteInfo={videoVoteInfo}
              closeModal={handleModalClose}
              openConfirmationModal={() => setConfirmationModalIsOpen(true)}
              submitVote={submitVote}
              reminder={reminder}
              disclaimer={disclaimer}
              isVotingOpen={isVotingOpen}
            />
          </StudentVideoContainer>
        </StudentModalContainer>
      </Modal>
      <ResultModal
        handleModalClose={() => setConfirmationModalIsOpen(false)}
        modalCloseAction={confirmationModalCloseAction}
        isOpen={confirmationModalIsOpen}
        type={ResultModalType.SUCCESS}
        closeOnOutsideClick={true}
        closeBtnText={'Back to gallery'}
        shareButton={shareButton}
      >
        <VoteConfirmationText>
          Your vote has been submitted. Please support the other three student
          groups by casting your vote!
        </VoteConfirmationText>
      </ResultModal>
    </>
  )
}
