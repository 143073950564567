export const CloseIcon = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.35355"
        y1="0.646447"
        x2="11.9602"
        y2="11.253"
        stroke="#757575"
      />
      <line
        y1="-0.5"
        x2="15"
        y2="-0.5"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 12 1)"
        stroke="#757575"
      />
    </svg>
  )
}
