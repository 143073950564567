import  {StyledTagContainer, StyledTagName } from './DisplayTags.styled'
import { DisplayTagsProps } from '../../interfaces/displayTags';


const categoryColors = {
    'category': '#535353',
    'industry': '#3E3E3E',
    'goal': '#303030',
  };

export const DisplayTags = ({ tags }: DisplayTagsProps) => {
  if (!Array.isArray(tags) || tags.length === 0) {
    return null
    }
    
    return (
      <StyledTagContainer>
        {tags.map((tag, index) => {

          return (
            <StyledTagName 
            $tagColor={categoryColors[tag.category as keyof typeof categoryColors]} 
            key={index}
            >
              {tag.name}
            </StyledTagName>

          )
        })}
      </StyledTagContainer>
    );
  };