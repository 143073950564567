import React from 'react'
import { HeadingProps } from '../../interfaces/heading'
import { StyledHeading } from './Heading.styled'

export const Heading = ({
  children,
  level,
  levelDisplay,
  isEyebrow = false,
  isUppercase = false,
  justify = 'left',
  title = '',
  margin,
  ...restStyle
}: HeadingProps) => {
  const classNames =
    `level-${levelDisplay ? levelDisplay : level} ` +
    (justify === 'center' ? `justify-${justify} ` : '') +
    (isEyebrow ? 'eyebrow ' : '') +
    (isUppercase ? 'to-uppercase' : '')

  const headingLevel = `h${level}`
  const content = children ? children : title
  const HeadingElement = ({
    ...props
  }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(headingLevel, props)
  return (
    <StyledHeading>
      <HeadingElement
        style={{ whiteSpace: 'pre-line', margin, ...restStyle }}
        className={classNames}
      >
        {typeof content === 'string'
          ? content.split('<br />').join('\n')
          : content}
      </HeadingElement>
    </StyledHeading>
  )
}
