import { styled } from 'styled-components'

export const StyledMultiDropdownSelector = styled.div`
  gap: 8px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  right: 16px;

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`

export const StyledMultiMenu = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  z-index: 8999;
  width: 390px;
  top: 100%;
  background: #000;
  display: ${props => (props.$isOpen ? 'initial' : 'none')};
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 767px) {
    width: 100%;
    margin: auto;
    max-width: calc(100vw - 47px);
    top: 86px;
  }

  @media (hover: none) and (max-width: 767px) {
    max-width: calc(100vw - 32px);
  }
`

export const StyledMultiDropdownList = styled.ul`
  width: 390px;
  max-height: 422px;
  overflow-y: auto;
  scrollbar-color: #5e5e5e #000;
  scrollbar-width: thin;
  padding: 8px 0px;
  margin: 0;
  border-radius: 8px;

  @media (max-width: 767px) {
    width: 100%;
  }
`
export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  //Hide built-in checkbox
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;

  //custom checkmark
  &:checked + label::after {
    content: '';
    display: block;
    position: absolute;
    top: 40%;
    left: 8px; //var(--spacing-1);
    transform: translateY(-50%) rotate(45deg);
    width: 8px; //var(--spacing-1);
    height: 20px; //var(--spacing-2);
    border: solid #000;
    border-width: 0 2px 2px 0;
  }

  &:checked + label::before {
    background-color: #fff; //var(--white);
    border-color: #fff; //var(--white);
  }

  &:hover {
    &:checked + label::before {
      border-color: #757575;
    }
  }
`

export const StyledLabel = styled.label`
  position: relative;
  cursor: pointer;
  gap: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
  text-transform: capitalize;

  //custom checkbox
  &::before {
    content: '';
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-color: rgba(#000, 0.8); //rgba(var(--black-rgba), 0.8);
    border: 1px solid #757575; //var(--gray-500);
    border-radius: 8px; //var(--spacing-1);
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-right: 8px;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-self: flex-end;

  @media (max-width: 767px) {
    width: 100%;
  }
`

export const StyledMultiButton = styled.div<{
  $disabled?: boolean
}>`
  background-color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #5e5e5e;
  border-radius: 0px 0px 0px 8px;

  &:hover {
    background-color: ${props => (props.$disabled ? '#000' : '#1B1B1B')};
  }

  button {
    color: ${props => (props.$disabled ? '#5e5e5e' : '#c6c6c6')};
    border: none;
    display: flex;
    justify-content: space-evenly;
    padding: 8px 16px;
    align-items: center;
    background-color: transparent;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    letter-spacing: 0.7px;
    line-height: normal;
    width: 100%;
    border-radius: 0px 0px 0px 8px;

    &:hover {
      color: ${props => (props.$disabled ? '#5e5e5e' : '#fff')};
    }
  }
`

export const StyledApplyButton = styled.div`
  background: linear-gradient(248.24deg, #ffffff 17.66%, #c6c6c6 71.37%);
  border-radius: 0px 0px 8px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #5e5e5e;

  button {
    color: #000;
    border: none;
    display: flex;
    justify-content: space-evenly;
    padding: 8px 16px;
    align-items: center;
    background-color: transparent;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    letter-spacing: 0.7px;
    line-height: normal;
    width: 100%;
    border-radius: 0px 0px 8px 0px;

    &:hover {
      color: #000;
    }
  }

  &:hover {
    background: linear-gradient(248deg, #c6c6c6 17.66%, #fff 71.37%);
  }
`
export const StyledCategoryName = styled.div`
  padding: 16px 42px 8px 16px;
  gap: 4px;
  text-transform: uppercase;
  color: #ABABAB;
`