import { css, styled } from 'styled-components'

const baseHeading = css`
  width: 100%;
  margin: 0;
  line-height: 1.1;
`

const lgViewportLineHeight = css`
  line-height: 1.2;
`

export const StyledHeading = styled.div`
  .level-1 {
    ${baseHeading}
    font-size: 32px; //var(--font-size-h1-mobile);
    font-weight: 200; //var(--font-weight-ultra-light);

    @media (min-width: 768px) {
      ${lgViewportLineHeight}
      font-size: 48px; //var(--font-size-h1-desktop);
    }
  }

  .level-2 {
    ${baseHeading}
    font-size: 24px; //var(--font-size-h2-mobile);
    font-weight: 600; //(--font-weight-extra-bold);
    margin-bottom: 25px;
    margin-top: 30px;
    color: #c6c6c6; //var(--gray-200);

    @media (min-width: 768px) {
      ${lgViewportLineHeight}
      font-size: 28px; //var(--font-size-h2-desktop);
      margin-bottom: 35px;
    }
  }

  .level-3 {
    ${baseHeading}
    font-size: 20px; //var(--font-size-h3-mobile);
    font-weight: 400; //(--font-weight-semi-bold);
    margin-bottom: 19px;
    margin-top: 45px;

    @media (min-width: 768px) {
      ${lgViewportLineHeight}
      font-size: 22px; //var(--font-size-h3-desktop);
    }

    a {
      color: '#fff'; //(--white);
      transition: all 0.2s linear; //var(--link-color-transition);
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: '#e2e2e2'; //var(--link-color-white-hover);
      }
    }
  }

  .level-4 {
    ${baseHeading}
    font-size: 18px; //var(--font-size-h4-mobile);
    font-weight: 600; //(--font-weight-extra-bold);

    @media (min-width: 768px) {
      ${lgViewportLineHeight}
      font-size: 20px; //var(--font-size-h4-desktop);
    }

    a {
      color: '#fff'; //var(--white);
      transition: all 0.2s linear; //var(--link-color-transition);
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: '#e2e2e2'; //var(--link-color-white-hover);
      }
    }
  }

  .level-5 {
    ${baseHeading}
    font-size: 16px; //var(--font-size-h5-mobile);
    font-weight: 500; //(--font-weight-bold);
    margin-bottom: 20px;

    @media (min-width: 768px) {
      ${lgViewportLineHeight}
      font-size: 18px; //var(--font-size-h5-desktop);
    }
  }

  .level-6 {
    ${baseHeading}
    font-size: 15px; //var(--font-size-h6-mobile);
    font-weight: 600; //(--font-weight-extra-bold);
    margin-bottom: 20px;
    text-transform: lowercase;

    @media (min-width: 768px) {
      ${lgViewportLineHeight}
      font-size: 16px; //var(--font-size-h6-desktop);
    }
  }

  .level-display {
    ${baseHeading}
    font-size: 48px; //var(--font-size-specialty-display-mobile);
    font-weight: 200; //var(--font-weight-ultra-light);

    @media (min-width: 768px) {
      ${lgViewportLineHeight}
      font-size: 64px; //var(--font-size-specialty-display-desktop);
    }
  }

  .eyebrow {
    ${baseHeading}
    font-size: 14px; //var(--font-size-specialty-label-mobile);
    font-weight: 600; //(--font-weight-extra-bold);
    margin-bottom: 20px;
    letter-spacing: 0.105em;
    text-transform: uppercase;

    @media (min-width: 768px) {
      ${lgViewportLineHeight}
      font-size: 16px; //var(--font-size-specialty-label-desktop);
    }
  }

  .to-uppercase {
    text-transform: uppercase;
  }

  &.justify-center {
    text-align: center;
  }
`
