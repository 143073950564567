import { MouseEventHandler } from 'react'
import { EducationLevels } from '../../interfaces/gallery'
import {
  StudentArtDetailsContainer,
  StyledArtDetails,
  StyledVoteButton,
} from './GalleryThumbnail.styled'

interface IStudentArtProps {
  title?: string
  educationLevel: number
  schoolName: string
  openModal?: MouseEventHandler<HTMLDivElement | HTMLButtonElement>
  isVotingOpen: () => boolean
}

export const StudentArtDetails = ({
  title,
  educationLevel,
  schoolName,
  openModal,
  isVotingOpen,
}: IStudentArtProps) => {
  return (
    <StudentArtDetailsContainer>
      <div>
        <StyledArtDetails>{`${EducationLevels[educationLevel]}, ${schoolName}`}</StyledArtDetails>
        <StyledArtDetails $fontStyle={'italic'}>{title}</StyledArtDetails>
      </div>
      {isVotingOpen() ? (
        <StyledVoteButton onClick={openModal}>Vote</StyledVoteButton>
      ) : null}
    </StudentArtDetailsContainer>
  )
}
