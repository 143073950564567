import { styled } from 'styled-components'

export const GalleryThumbnailContainer = styled.div<{ $isStudent: boolean }>`
  padding-bottom: 24px; //var(--spacing-4);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 8px;
  color: #fff;

  &:hover {
    img {
      opacity: 1;
    }
  }

  img {
    border-radius: 8px; //var(--spacing-1);
    opacity: 1;
    transition: opacity 0.3s linear;
    aspect-ratio: 16/9;

    @media (hover: hover) {
      opacity: 0.8;
    }
  }
`

export const DetailsContainer = styled.div<{ $isStudent: boolean }>`
  .level-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    font-weight: 350; //var(--font-weight-light);
    width: 100%;
    margin: ${props =>
      props.$isStudent ? '0px' : '8px 0'}; //var(--spacing-1) var(--spacing-0);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: ${props => (props.$isStudent ? 'reset' : '18px')};

    @media (max-width: 767px) {
      word-break: ${props => (props.$isStudent ? 'break-all' : 'break-word')};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font-size: 16px; //var(--font-size-h5-mobile);
      line-height: ${props => (props.$isStudent ? '100%' : '120%')};
      font-weight: ${props => (props.$isStudent ? 400 : 350)};
    }
  }
`

export const ThumbnailContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  position: relative;
`;

export const StudentArtDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledArtDetails = styled.p<{ $fontStyle?: string }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  //min-height: 3em;
  margin: 0;
  gap: 8px;
  font-style: ${props => (props.$fontStyle ? props.$fontStyle : 'normal')};

  @media (max-width: 767px) {
    font-size: 12px;
    font-weight: 700;
  }
`

export const StyledVoteButton = styled.button`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 40px;
  border: 1px solid #05c3dd;
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  justify-content: center;
  align-self: flex-start;
  cursor: pointer;

  @media (max-width: 767px) {
    align-self: stretch;
  }

  &:hover {
    background: linear-gradient(248deg, #05c3dd 27.85%, #20ffff 81.23%);
    color: #000;
  }
`