
import { styled } from 'styled-components'

export const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: flex-start;
`

export const StyledTagName = styled.div<{ $tagColor?: string }>`
  height: 20px;
  background-color:${(props) => props.$tagColor};
  color: #ABABAB;
  padding: 4px 8px;
  text-transform: uppercase;
  line-height: 100%;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2.4px;
  border-radius: 2px;
  white-space: nowrap;
  word-break: keep-all;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
`