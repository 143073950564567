export const WarningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M2.08057 21.025L11.9998 3.925L21.9191 21.025H2.08057ZM3.98634 19.9308H20.0133L11.9998 6.11345L3.98634 19.9308ZM12.0639 18.4769C12.2456 18.4769 12.3998 18.412 12.5267 18.2823C12.6537 18.1525 12.7171 17.9993 12.7171 17.8227C12.7171 17.646 12.6521 17.4901 12.522 17.3548C12.3919 17.2196 12.2361 17.1519 12.0545 17.1519C11.8729 17.1519 11.7171 17.2193 11.5871 17.3539C11.4571 17.4886 11.3921 17.6478 11.3921 17.8317C11.3921 18.0084 11.4587 18.1601 11.5918 18.2869C11.725 18.4136 11.8823 18.4769 12.0639 18.4769ZM11.5075 16.0808H12.6017V10.7385H11.5075V16.0808Z"
        fill="#F52500"
      />
    </svg>
  )
}
